import React from "react"
import { Link } from "gatsby"

const NavItem = ({ link, landingPage, route, title }) => {
  return link ? (
    <a href={link}>{title}</a>
  ) : landingPage ? (
    <Link activeClassName="active" to={`/${landingPage.slug.current}`}>
      {title}
    </Link>
  ) : route ? (
    <Link activeClassName="active" to={route}>
      {title}
    </Link>
  ) : null
}

export default NavItem
