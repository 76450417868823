import React, { useEffect, useState, useRef, useCallback } from "react"

import { useTheme } from "../../hooks/useTheme"
import HeaderDisplay from "./HeaderDisplay"

const HeaderContainer = ({ location, navItems }) => {
  const [scrollPositionInside, updateState] = useState(false)
  const [navIsVisible, updateNav] = useState(false)

  const { theme } = useTheme()

  const scheduledAnimationFrame = useRef()
  const scrollRef = useRef()

  const onScroll = useCallback(() => {
    if (scheduledAnimationFrame.current) return

    scheduledAnimationFrame.current = true
    requestAnimationFrame(updateScrollState)
  }, [])

  useEffect(() => {
    scheduledAnimationFrame.current = false
    scrollRef.current = false
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  useEffect(() => {
    updateNav(false)
  }, [location])

  function updateScrollState() {
    if (window.scrollY > 100 && !scrollRef.current) {
      scrollRef.current = true
      updateState(true)
    } else if (window.scrollY < 100 && scrollRef.current) {
      scrollRef.current = false
      updateState(false)
    }
    scheduledAnimationFrame.current = false
  }

  return (
    <HeaderDisplay
      theme={theme}
      scrollPositionInside={scrollPositionInside}
      navIsVisible={navIsVisible}
      updateNav={updateNav}
      navItems={navItems}
    />
  )
}

export default HeaderContainer
