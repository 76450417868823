import React from "react"
import { Link } from "gatsby"
import { MdMenu } from "react-icons/md"

import {
  WebsiteHeader,
  WebsiteHeaderInner,
  MobileNavToggleButton,
} from "./Header.styles"
import Logo from "./Logo"
import MainNavigation from "./MainNavigation"

const HeaderDisplay = ({
  navItems,
  navIsVisible,
  theme,
  scrollPositionInside,
  updateNav,
  siteTitle,
}) => {
  return (
    <WebsiteHeader scrollPositionInside={scrollPositionInside} theme={theme}>
      <WebsiteHeaderInner>
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <h1 aria-hidden style={{ margin: 0, display: "none" }}>
          {siteTitle}
        </h1>

        <MainNavigation
          navItems={navItems}
          navIsVisible={navIsVisible}
          theme={theme}
        />
        <MobileNavToggleButton onClick={() => updateNav(!navIsVisible)}>
          <MdMenu className="icon" />
        </MobileNavToggleButton>
      </WebsiteHeaderInner>
    </WebsiteHeader>
  )
}

export default HeaderDisplay
