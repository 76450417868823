import React from "react"
import { Nav } from "./Header.styles"
import NavItem from "./NavItem"

const MainNavigation = ({ theme, navIsVisible, navItems }) => {
  return (
    <Nav theme={theme} navIsVisible={navIsVisible}>
      {navItems.map(item => (
        <NavItem key={item._key} {...item} />
      ))}
      <NavItem key="scholarship" link="/arabiyatscholarship" title="Scholarship" />
    </Nav>
  )
}

export default MainNavigation
