import React from "react"
import { Global, css } from "@emotion/core"
import { fonts } from "./variables.styles"

import Black from "../fonts/WorkSans-Black.woff2"
import Bold from "../fonts/WorkSans-Bold.woff2"
import Regular from "../fonts/WorkSans-Regular.woff2"

const styles = css`
  @font-face {
    font-family: "WorkSans Regular";
    src: url(${Regular}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "WorkSans Bold";
    src: url(${Bold}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "WorkSans Black";
    src: url(${Black}) format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-family: ${fonts.regular};
    font-size: 62.5%;
  }

  * {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.01);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    margin: 0 0 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    height: auto;
    display: block;
    width: 100%;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .link {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  strong {
    font-family: ${fonts.bold};
  }

  header,
  video,
  article,
  section,
  main,
  nav,
  footer {
    display: block;
  }

  .link {
    display: block;
    position: relative;
  }

  #modal {
    position: relative;
    z-index: 100;
  }
  svg {
    display: block;
  }
`
export default () => <Global styles={styles} />
