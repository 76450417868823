export const colors = {
  primary: { blue: "#0090FF", black: "#000000" },
}

export const fixedValues = {
  headerHeightMobile: "80px",
  headerHeight: "120px",
  largeHeaderHeight: "160px",
}

export const fonts = {
  title: "WorkSans Black",
  bold: "WorkSans Bold",
  regular: "WorkSans Regular",
}

export const spacing = {
  mobile: "25px",
  tablet: "48px",
  desktop: "60px",
  dekstop__l: "80px",
}
