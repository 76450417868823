import styled from "@emotion/styled"
import { css } from "@emotion/core"

import {
  fixedValues,
  colors,
  fonts,
  spacing,
} from "../../styles/variables.styles"
import { mq } from "../../styles/helpers.styles"
import { wrapper } from "../../styles/shared.styles"

export const WebsiteHeader = styled.header`
  height: ${fixedValues.headerHeightMobile};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: ${({ scrollPositionInside, theme }) =>
    scrollPositionInside
      ? theme === "blue"
        ? colors.primary.blue
        : "#FFF"
      : "transparent"};
  transition: background ease-in-out 200ms;
  .logo {
    z-index: 2;
    .cls-3 {
      fill: ${({ theme }) => (theme === "blue" ? "#FFF" : colors.primary.blue)};
    }
  }
  ${mq.tablet} {
    height: ${fixedValues.headerHeight};
  }
  ${mq.desktop__l} {
    height: ${fixedValues.largeHeaderHeight};
  }
`

export const WebsiteHeaderInner = styled.div`
  ${wrapper}
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
`

const mobileNavStyles = props => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`} ${spacing.mobile}
    48px;
  background: ${props.theme === "blue" ? colors.primary.blue : "#FFF"};
  opacity: ${props.navIsVisible ? "0.9" : "0"};
  visibility: ${props.navIsVisible ? "visible" : "hidden"};
  transition: opacity 0.3s ease-in-out;

  a {
    font-family: ${fonts.title};
    font-size: 4.2rem;
    line-height: 1.1em;
    padding-bottom: 0.5em;
    display: block;
    &.active {
      color: ${props.theme === "blue" ? "#FFF" : colors.primary.blue};
    }
  }

  ${mq.tablet} {
    padding: ${`calc(${fixedValues.headerHeight} + 5vh)`} ${spacing.tablet} 48px;
    a {
      font-size: 6.2rem;
    }
  }
`

const revertMobileNavStyles = () => css`
  position: relative;
  top: unset;
  left: unset;
  width: auto;
  height: auto;
  padding: 0px;
  background: none;
  visibility: visible;
  opacity: 1;

  a {
    font-family: ${fonts.regular};
    display: inline;
    margin: 0;
    &.active {
      color: #000;
    }
  }
`

const desktopNavStyles = props => css`
  display: flex;
  flex: 0 0 50%;
  justify-content: space-between;
  padding: 0px 0px 0px 60px;
  a {
    font-family: ${fonts.regular};
    font-size: 2em;
    color: ${props.theme === "blue" ? "#FFF" : "#000"};
    &.active {
      font-family: ${fonts.bold};
    }
  }
`

export const Nav = styled.nav`
  ${mobileNavStyles};
  ${mq.laptop} {
    ${revertMobileNavStyles};
    ${desktopNavStyles};
  }
  ${mq.desktop__l} {
    a {
      font-size: 2.6em;
    }
  }
`

export const MobileNavToggleButton = styled.div`
  position: relative;
  z-index: 2;
  cursor: pointer;
  .icon {
    font-size: 40px;
  }
  ${mq.laptop} {
    display: none;
  }
`
