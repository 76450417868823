import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Global from "../styles/global.styles"
import { ThemeProvider } from "../hooks/useTheme"
import Header from "./Header"

const Layout = ({ children, location }) => {
  const { mainMenu } = useStaticQuery(
    graphql`
      query getMenuItems {
        mainMenu: sanityMenu(_id: { eq: "menu" }) {
          menuItems: _rawLinks(resolveReferences: { maxDepth: 2 })
        }
      }
    `
  )
  return (
    <>
      <Global />
      <ThemeProvider>
        <Header navItems={mainMenu.menuItems} location={location} />
        <main>{children}</main>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
