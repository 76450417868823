import React, { createContext, useState, useContext } from "react"
import PropTypes from "prop-types"

const initialState = "blue"

const ThemeContext = createContext(initialState)

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialState)

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export const useTheme = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const updateTheme = (newTheme) => {
    setTheme(newTheme)
  }
  return {
    theme,
    setTheme: updateTheme,
  }
}
