/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { mq } from "../../styles/helpers.styles"

const Logo = () => {
  return (
    <svg
      css={css`
        height: 32px;
        width: auto;
        display: block;
        .cls-1 {
          fill: none;
        }
        .cls-2 {
          clip-path: url(#clip-path);
        }
        .cls-3 {
          fill: #008fff;
        }
        ${mq.tablet} {
          height: 42px;
        }
        ${mq.desktop__l} {
          height: 52px;
        }
      `}
      viewBox="0 0 1248.58 325.95"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(0 0)">
          <rect className="cls-1" width="1248.58" height="325.95" />
        </clipPath>
      </defs>
      <title>Asset 4</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g className="cls-2">
            <path
              className="cls-3"
              d="M111.32,152.75c-3-1.64-17.42-7.53-43.15-17.73-10.2-3.64-15.48-9.58-15.48-17.48,0-9.53,8.25-16.81,21.73-16.81,15.17,0,28.65,6.25,40.18,18.45L137,91.87c-15.48-17.48-35.87-26.35-61.25-26.35-19.42,0-35.2,4.92-47.1,14.81S10.87,103,10.87,118.51c0,20.46,9.89,35.57,30,45.47,3.95,1.94,11.53,5.28,22.7,9.53,11.54,4.31,18.82,7.28,21.73,8.92,8.93,4.25,13.54,10.2,13.54,17.12,0,10.5-10.87,18.75-26.34,18.75-17.48,0-36.24-9.53-48.08-25.37L0,219.28c16.82,23.36,43.16,35.56,72.11,35.56,20.76,0,37.57-5.28,50.38-16.14s19.12-24.34,19.12-40.49c0-19.42-10.19-34.59-30.29-45.46"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M203.74,143.55c5.64-25.07,24-40.54,49.1-40.54,24.65,0,42.8,15.47,46.44,40.54Zm49.1-76.42c-27.37,0-49.71,8.86-67.19,26.65C168.53,111.26,160,133.66,160,160.67c0,47.41,29.63,93.47,96.82,93.47,29.62,0,55.66-8.19,77.69-24.7l-17.11-30.59a100.88,100.88,0,0,1-58.94,18.45c-30.66,0-49.05-16.51-54.69-41.15H340.07A159.58,159.58,0,0,0,341.4,157c0-27.68-8.25-49.41-25-65.56-16.51-16.08-37.58-24.34-63.56-24.34"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M431.6,219.61c-18.15,0-29.68-8.92-29.68-23.37,0-18.45,15.18-23.06,47.1-23.43,12.21,0,22.76-.3,32.3-1.27-2.31,28-22.4,48.07-49.72,48.07m9.23-152.48q-40.06,0-73.14,20.76l16.14,31.93a107.83,107.83,0,0,1,55.3-15.17c26.34,0,40.55,11.53,42.85,34.29-3.94.3-18.14,1-42.85,1.64-49.05.3-79,21.06-79,56.27,0,33.93,24,58,64.52,58,25.37,0,45.1-8.56,58.58-25.68V250.2h38.91V143.55c0-56.69-39.52-76.42-81.34-76.42"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M678.47,197.88c-9.9,10.2-22.4,15.11-37.21,15.11-15.12,0-27.68-4.91-37.52-15.11s-14.87-23.07-14.87-38.54,5-28,14.87-37.88c9.84-10.2,22.4-15.12,37.52-15.12,14.81,0,27.31,4.92,37.21,15.12,9.89,9.89,14.8,22.4,14.8,37.88s-4.91,28.34-14.8,38.54m14.8-105.07c-14.8-17.12-33.92-25.68-56.63-25.68-25.67,0-47.4,8.56-64.88,26-17.42,17.12-26,39.15-26,65.86,0,26.35,8.19,48.08,25,65.5s38.18,26,64.22,26c23.37,0,42.49-8.26,56.94-24.41v10.57c0,30.59-21.37,52-57.91,52a103,103,0,0,1-47.26-11.57l-16.23,33.48Q600,326,635,326c29.32,0,53-8.55,71.14-25.37s27.32-39.51,27.32-68.16v-161H693.27Z"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M879.73,175.51c0,24-14.14,39.82-37.88,39.82-23.37,0-37.51-15.78-37.51-39.82v-104H761.19v104c0,49.71,30.95,79,80.66,79,50.39,0,81-29.32,81-79v-104H879.73Z"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M1074.89,197.21h-.06V0H1032V198.06a62.83,62.83,0,0,0,62.82,62.82h3.4V219.12h-1.39a22,22,0,0,1-22-21.91"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M993.47,197.21h-.06V0H950.62V198.06a62.83,62.83,0,0,0,62.82,62.82h3.4V219.12h-1.39a22,22,0,0,1-22-21.91"
              transform="translate(0 0)"
            />
            <path
              className="cls-3"
              d="M1218.29,152.75c-3-1.64-17.42-7.53-43.1-17.73-10.26-3.64-15.48-9.58-15.48-17.48,0-9.53,8.2-16.81,21.73-16.81,15.12,0,28.65,6.25,40.12,18.45L1244,91.87c-15.48-17.48-35.87-26.35-61.24-26.35-19.43,0-35.21,4.92-47,14.81s-17.79,22.71-17.79,38.18c0,20.46,9.83,35.57,29.93,45.47,3.94,1.94,11.53,5.28,22.76,9.53,11.47,4.31,18.75,7.28,21.73,8.92,8.86,4.25,13.47,10.2,13.47,17.12,0,10.5-10.86,18.75-26.34,18.75-16.52,0-34.28-8.56-46.17-23L1109.83,223c16.81,21,41.86,31.87,69.31,31.87,20.69,0,37.51-5.28,50.37-16.14s19.07-24.34,19.07-40.49c0-19.42-10.2-34.59-30.29-45.46"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
