import { css } from "@emotion/core"
import styled from "@emotion/styled"

import { fixedValues, fonts, colors, spacing } from "./variables.styles"
import { mq } from "./helpers.styles"

export const fixedHeightComponent = css`
  height: calc(100vh - ${fixedValues.headerHeightMobile});
  ${mq.tablet} {
    height: calc(100vh - ${fixedValues.headerHeight});
  }
  ${mq.desktop__l} {
    height: calc(100vh - ${fixedValues.largeHeaderHeight});
    max-width: 1600px;
  }
`

export const wrapper = css`
  width: 100%;
  padding: 0 ${spacing.mobile};
  margin: 0 auto;
  ${mq.tablet} {
    padding: 0 ${spacing.tablet};
  }
  ${mq.desktop} {
    padding: 0 ${spacing.desktop};
  }
  ${mq.desktop__l} {
    max-width: 1920px;
    padding: 0 ${spacing.dekstop__l};
  }
`

export const halfWrapper = css`
  width: 100%;
  ${mq.desktop__l} {
    max-width: 960px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-gap: ${`calc(${spacing.mobile} * 2)`};
  ${mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing.tablet};
  }
  ${mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${spacing.desktop};
  }
  ${mq.desktop__l} {
  }
`

export const link = css`
  font-family: ${fonts.bold};
  color: #000;
  text-decoration: underline;
  &:hover {
    color: ${colors.primary.blue};
  }
`
